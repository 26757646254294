export default function Toastify() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			id="Layer_1"
			viewBox="0 0 486.4 486.4"
		>
			<path
				className="fill-[#AD6B1A]"
				d="M451.2,202.8c24-23.2,35.2-53.6,35.2-87.2c0-74.4-61.6-96.8-139.2-96.8c-41.6,0-78.4,6.4-104,24  c-25.6-17.6-63.2-24-104.8-24C60.8,19.6,0,41.2,0,115.6c0,33.6,11.2,64,35.2,87.2v190.4c0,48.8-25.6,89.6,23.2,89.6h370.4  c48.8,0,23.2-40.8,23.2-89.6V202.8H451.2z"
			/>
			<path
				className="fill-[#E5A641]"
				d="M451.2,187.6c24-23.2,35.2-53.6,35.2-87.2c0-74.4-61.6-96.8-139.2-96.8c-41.6,0-78.4,6.4-104,24  C217.6,10,180,3.6,138.4,3.6C60.8,4.4,0,26,0,100.4c0,33.6,11.2,64,35.2,87.2V378c0,48.8-25.6,88.8,23.2,88.8h370.4  c48.8,0,23.2-40,23.2-88.8V187.6H451.2z"
			/>
			<path
				className="fill-[#C47E22]"
				d="M428,466.8c48.8,0,23.2-40,23.2-88.8V187.6c24-23.2,35.2-53.6,35.2-87.2c0-74.4-61.6-96.8-139.2-96.8  c-41.6,0-78.4,6.4-104,24c-25.6-17.6-64-24-105.6-24"
			/>
			<path
				className="fill-[#F2E5A2]"
				d="M419.2,194c16-19.2,25.6-44,25.6-71.2c0-60.8-52-78.4-115.2-78.4c-33.6,0-64.8,5.6-85.6,19.2  c-20.8-14.4-51.2-19.2-85.6-19.2C95.2,44.4,41.6,62,41.6,122.8c0,27.2,9.6,52,25.6,71.2v155.2c0,40-14.4,85.6,24.8,85.6h301.6  c40,0,24.8-46.4,24.8-85.6V194H419.2z"
			/>
			<g>
				<path
					className="fill-[#E2CE8D]"
					d="M394.4,434.8c40,0,24.8-46.4,24.8-85.6V194c16-19.2,25.6-44,25.6-71.2c0-60.8-52-78.4-115.2-78.4   c-33.6,0-64.8,5.6-85.6,19.2c-20.8-14.4-50.4-19.2-84.8-19.2"
				/>
				<circle
					className="fill-[#E2CE8D]"
					cx="147.2"
					cy="160.4"
					r="20"
				/>
				<circle className="fill-[#E2CE8D]" cx="248" cy="380.4" r="20" />
			</g>
			<circle className="fill-[#F2E5A2]" cx="379.2" cy="123.6" r="20" />
			<g>
				<circle
					className="fill-[#E2CE8D]"
					cx="139.2"
					cy="335.6"
					r="12.8"
				/>
				<circle
					className="fill-[#E2CE8D]"
					cx="123.2"
					cy="235.6"
					r="10.4"
				/>
				<circle className="fill-[#E2CE8D]" cx="196" cy="286" r="10.4" />
			</g>
			<circle className="fill-[#F2E5A2]" cx="323.2" cy="230.8" r="10.4" />
			<g>
				<circle
					className="fill-[#E2CE8D]"
					cx="103.2"
					cy="107.6"
					r="8"
				/>
				<circle className="fill-[#E2CE8D]" cx="188" cy="210" r="8" />
			</g>
			<circle className="fill-[#F2E5A2]" cx="304.8" cy="138" r="8" />
			<circle className="fill-[#E2CE8D]" cx="257.6" cy="279.6" r="4.8" />
			<circle className="fill-[#F2E5A2]" cx="367.2" cy="274" r="4.8" />
		</svg>
	);
}
