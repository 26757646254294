export default function EDI() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="2500"
			viewBox="0 0 192.756 192.756"
		>
			<g fillRule="evenodd" clipRule="evenodd">
				<path fill="#fff" d="M0 0h192.756v192.756H0V0z" />
				<path d="M182.143 135.258h7.779V57.479h-7.779v77.779zM133.074 128.217V64.522h1.721c29.746 0 36.893 19.129 36.893 31.742 0 15.451-10.617 31.953-36.367 31.953h-2.247zm-7.777 7.041h10.023c31.637 0 44.145-20.074 44.145-38.994 0-15.661-8.197-38.784-44.67-38.784h-9.498v77.778zM85.873 135.258h33.793v-7.041H93.65v-28.59h24.86v-7.042H93.65V64.522h26.016v-7.043H85.873v77.779zM80.208 57.902H2.834v9.655l.455-.454h11.456L2.834 79.013v12.383l24.293-24.293h11.456L2.834 102.852v14.234l49.984-49.983h11.456l-61.44 61.44v6.732h7.039l61.134-61.134v11.456L21.33 135.275h58.878V57.902z" />
			</g>
		</svg>
	);
}
