export default function Chai() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="#ffffff"
			height="800px"
			viewBox="0 0 24 24"
			role="img"
		>
			<path d="M22.224 5.831 12.23.061a.462.462 0 0 0-.462 0l-9.992 5.77a.462.462 0 0 0-.231.4v11.538c0 .165.088.318.23.4l9.993 5.77a.462.462 0 0 0 .462 0l9.993-5.77a.462.462 0 0 0 .23-.4V6.231a.462.462 0 0 0-.23-.4zm-.63 11.707L12 23.078l-9.593-5.539V6.462L12 .923l9.593 5.539v11.076zM11.295 8.186c.387-.277.792-.497 1.213-.659s.843-.243 1.263-.243c.26 0 .502.038.727.113.225.076.42.185.584.328s.294.32.39.532c.094.212.142.454.142.727 0 .329-.065.656-.195.98-.13.325-.304.625-.522.902s-.47.52-.756.727-.582.357-.89.448l-.136-.227a2.087 2.087 0 0 0 .5-.35 2.275 2.275 0 0 0 .39-.484c.108-.18.19-.37.25-.571a2.19 2.19 0 0 0 .087-.614c0-.238-.035-.463-.104-.675a1.626 1.626 0 0 0-.305-.555 1.426 1.426 0 0 0-.496-.373 1.602 1.602 0 0 0-.679-.136c-.311 0-.597.06-.856.182-.26.12-.494.284-.701.49s-.389.44-.542.707a4.874 4.874 0 0 0-.383.837 5.617 5.617 0 0 0-.23.886 4.292 4.292 0 0 0 .007 1.675c.055.285.138.567.246.844.108.277.242.538.402.785.16.247.345.463.555.649a2.583 2.583 0 0 0 .7.445c.258.11.539.165.841.165.208 0 .41-.028.607-.084a2.987 2.987 0 0 0 .568-.228 3.21 3.21 0 0 0 .516-.337 3.967 3.967 0 0 0 .451-.422l.215.208c-.19.25-.417.492-.679.723a5.543 5.543 0 0 1-.847.617 4.624 4.624 0 0 1-.95.428 3.21 3.21 0 0 1-.984.16 2.41 2.41 0 0 1-.892-.166c-.28-.11-.536-.26-.77-.448s-.442-.409-.626-.662c-.183-.253-.34-.522-.467-.808s-.225-.58-.292-.883-.101-.599-.101-.889c0-.42.07-.851.21-1.295s.335-.874.582-1.291.537-.813.873-1.185a6.721 6.721 0 0 1 1.084-.973z" />
		</svg>
	);
}
