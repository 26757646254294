export default function ChromeDevTools() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="800px"
			viewBox="0 0 1522 1522"
			fill="none"
		>
			<rect id="d" width="1520" height="1520" rx="120" />

			<radialGradient id="f" cx="15%" cy="14%" r="100%">
				<stop offset="0" />

				<stop offset="1" />
			</radialGradient>

			<radialGradient id="g" cx="-.2%" cy=".1%" r="136%">
				<stop offset="0" stopOpacity=".2" />

				<stop offset="1" />
			</radialGradient>

			<radialGradient id="h" cx="85%" cy="13%" r="154%">
				<stop offset="0" />

				<stop offset="1" />
			</radialGradient>

			<radialGradient id="i" cx="41%" cy="0" r="57%">
				<stop offset="0" stopOpacity=".15" />

				<stop offset="1" />
			</radialGradient>

			<path
				id="a"
				d="M761 1361a600 600 0 1 0 0-1200 600 600 0 0 0 0 1200z"
			/>

			<mask id="j" width="120" height="120" x="0" y="0">
				<use />
			</mask>

			<circle id="b" cx="761" cy="761" r="280" />

			<mask id="k" width="56" height="56" x="0" y="0">
				<use />
			</mask>

			<circle id="c" cx="761" cy="761" r="200" />

			<mask id="l" width="40" height="40" x="0" y="0">
				<use />
			</mask>

			<use fill="#2879ff" />

			<g strokeOpacity=".2" strokeWidth="5">
				<path d="m-199-199 1920 1920m0-1920L-199 1721M1038.5-199v1920m-555-1920v1920M1721 1038.5H-199m1920-555H-199" />

				<circle cx="761" cy="761" r="390" />

				<path d="M-199 761h1920M761-199v1920" />
			</g>

			<circle cx="761" cy="761" r="600" fill="url(#f)" />

			<path
				d="M1400 0H120C54 0 0 54 0 120v10C0 64 54 10 120 10h1280c66 0 120 54 120 120v-10c0-66-54-120-120-120z"
				opacity=".2"
			/>

			<path
				fill="#000000"
				d="M1401 1511H121c-66 0-120-54-120-120v10c0 66 54 120 120 120h1280c66 0 120-54 120-120v-10c0 66-54 120-120 120z"
				opacity=".1"
			/>

			<path
				fill="url(#g)"
				d="M495.9 675.8 251 431l267.2 458.3a273.7 273.7 0 0 1-22.4-213.5z"
			/>

			<path
				fill="url(#h)"
				d="M820.7 1031 731 1365.6 996.6 901a274.2 274.2 0 0 1-176 130z"
			/>

			<path
				fill="url(#i)"
				d="m962 575.3 334.2-89.3H759.7A273.7 273.7 0 1 0 962 575.3z"
			/>

			<g strokeWidth="40">
				<use mask="url(#j)" />

				<use mask="url(#k)" />

				<use mask="url(#l)" />
			</g>

			<path d="M761 481h530v20H761zm242.5 420-265 459-17.3-10 265-459zm-485 0-265-459 17.3-10 265 459z" />
		</svg>
	);
}
